* {
  box-sizing: border-box;
}

a.header-link,
a.header-link:visited,
a.header-link:active {
  text-decoration: none;
  color: var(--header-text-color);
}

a.header-link:hover {
  color: var(--accent-two);
}

.header-container {
  height: 7.5vh;
  position: fixed;
  width: 100%;
  background: var(--header-color);
  border-bottom: 0.2vh solid var(--header-text-color);
  display: flex;
  color: var(--header-text-color);
  font-family: var(--font-family);
}

.header-section-1 {
  flex: 1;
  border-right: 0.2vh solid var(--header-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 5vh;
}

.header-section-2 {
  flex: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-content-list {
  padding: 0;
  display: flex;
  column-gap: 2.5vh;
  list-style-type: none;
  font-weight: var(--font-medium);
  font-size: 1.8vh;
}

/* Media Queries */
@media (width: 344px) {
  .header-section-2 {
    flex: 6;
  }

  .logo {
    width: 5vh;
  }

  .header-content-list {
    column-gap: 1.75vh;
    font-size: 1.4vh;
  }
}

@media (width: 340px) {
  .header-section-2 {
    flex: 6;
  }

  .logo {
    width: 5vh;
  }

  .header-content-list {
    column-gap: 1.75vh;
    font-size: 1.4vh;
  }
}

@media (width: 360px) {
  .header-section-2 {
    flex: 6;
  }

  .header-content-list {
    column-gap: 2vh;
    font-size: 1.6vh;
  }
}

@media (min-width: 390px) {
  .header-section-2 {
    flex: 6;
  }

  .header-content-list {
    column-gap: 2vh;
    font-size: 1.6vh;
  }
}

@media (min-width: 540px) {
  .header-container {
    height: 8vh;
  }

  .header-section-2 {
    flex: 6;
  }

  .logo {
    width: 7vh;
  }

  .header-content-list {
    column-gap: 4vh;
    font-size: 2vh;
  }
}

@media (min-width: 1024px) and (height < 1000px) {
  .header-container {
    height: 12vh;
  }

  .header-section-2 {
    flex: 6;
  }

  .logo {
    width: 10vh;
  }

  .header-section-2 {
    justify-content: end;
    padding-right: 6vh;
  }

  .header-content-list {
    column-gap: 8vh;
    font-size: 2.5vh;
  }
}
