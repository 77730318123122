/* Container positioning */
.homeAbout-container {
  display: flex;
}

.about-section {
  flex: 1;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;
  padding-top: 7rem;
  /* flex: 1;
  display: flex;
  height: var(--homeAbout-height);
  flex-direction: column; */
}

/* Left Section */
.about-left {
  flex: 1.1;
  background-color: var(--background-color);
  display: flex;
}

.about-left-top {
  flex: 1;
}

.about-left-middle {
  flex: 2;
}

.about-left-bottom {
  flex: 1;
  position: relative;
  top: 1rem;
  left: 0rem;
}

.number {
  font: var(--number-font);
  position: relative;
  left: 5rem;
  top: 1rem;
  color: var(--accent-two);
}

.who-am-I {
  font-size: 5.5rem;
  position: relative;
  left: 16rem;
  top: -4rem;
}

.who-am-I-answer {
  font-size: 1.25rem;
  position: relative;
  left: 12rem;
  top: -1rem;
}

.button-1 {
  position: relative;
  left: 13rem;
  top: -5rem;
}

.button-2 {
  position: relative;
  left: 25rem;
  top: -8rem;
}

/* Right Section */
.about-right {
  flex: 1;
  display: flex;
  background-color: var(--background-color);
  align-items: center;
}

.homeAbout-icon-container,
.homeAbout-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.homeAbout-icon-container {
  align-items: end;
}

.right-rectangle {
  border: 0.1rem solid var(--accent-one);
  border-radius: 0.5rem;
  width: 16em;
  height: 10rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: var(--accent-one);
  margin: 1rem;
}

a:hover .right-rectangle {
  background-color: var(--accent-one);
  color: var(--background-color);
}

.right-rectangle h1 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
}

.link-style {
  text-decoration: none;
}

/* For Phones */
@media (max-width: 768px) {
  .homeAbout-container {
    flex-direction: column;
    width: 100vw;
  }

  .about-left,
  .about-right {
    height: 100vh;
  }
}
