/* Container positioning */
.homeProjects-container {
  display: flex;
}

.homeProjects-section {
  flex: 1;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;
  /* padding-top: 7rem;
  padding-top: 5rem;
  flex: 1;
  display: flex;
  height: var(--homeProjects-height);
  flex-direction: column; */
}

/* Left Section */
.projectsLeft {
  background-color: var(--background-color);
  display: flex;
}

.projectsLeftTop,
.projectsLeftBottom {
  flex: 1;
}

.projectsLeftMiddle {
  flex: 2;
}

.numberProjects {
  font: var(--number-font);
  position: relative;
  left: 5rem;
  top: 1rem;
  color: var(--accent-two);
}

.myProjects {
  font-size: 5rem;
  position: relative;
  left: 14rem;
  top: -4rem;
}

.myProjectsAnswer {
  font-size: 1.25rem;
  position: relative;
  left: 14rem;
  top: -1rem;
}

/* Right Section */
.projects-right {
  background-color: var(--background-color);
  display: flex;
}

.projects-right-top {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projects-right-bottom {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects-right-bottom-top,
.projects-right-bottom-bottom {
  flex: 1;
  border: 0.1rem solid var(--accent-one);
  border-radius: 0.5rem;
  display: flex;
  margin: 1.5rem;
  width: 30rem;
}

.projects-right-bottom-top-left {
  flex: 1;
}

.projects-right-bottom-bottom-right {
  flex: 1;
}

.projects-right-bottom-top-right,
.projects-right-bottom-bottom-right {
  border-left: 0.1rem solid var(--accent-one);
}

.projects-right-bottom-top-right,
.projects-right-bottom-bottom-left {
  flex: 1.6;
  display: flex;
  flex-direction: column;
  color: var(--accent-one);
}

.project-title {
  flex: 1;
  display: flex;
  justify-content: center;
}

.project-title h3 {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  margin-top: 0.5rem;
}

.project-description {
  flex: 2;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: -2rem;
}

.project-description p {
  font-size: 1.05rem;
  font-family: "Poppins", sans-serif;
}

.project-extras {
  flex: 1;
  display: flex;
  margin-top: -0.5rem;
}

.project-timeline {
  flex: 2;
  padding-left: 1rem;
  padding-top: 0.5rem;
  display: flex;
  gap: 0.3rem;
}

.project-timeline span {
  font-size: 0.8rem;
  font-family: "Poppins";
}

.project-links {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding-right: 1rem;
}

.most-recent-projects {
  font-size: 2rem;
  font-family: "Poppins";
  color: var(--accent-one);
  position: relative;
}

.projectImage-1 {
  height: 100%;
  width: 100%;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.projectImage-2 {
  height: 100%;
  width: 100%;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/* For Phones */
@media (max-width: 768px) {
  .homeProjects-container {
    flex-direction: column;
    width: 100vw;
  }

  .projects-left,
  .projects-right {
    height: 100vh;
  }
}
