/* IPhone */
.iphone {
  width: 200px;
  height: 400px;
  background-color: var(--accent-two);
  border-radius: 25px;
  margin: 50px auto;
  position: relative;
}

/* Iphone Notch */
.iphone-notch {
  width: 120px;
  height: 18px;
  background-color: var(--accent-two);
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
}

.iphone-speaker {
  flex: 2;
  background-color: lightgray;
  margin: 0.4rem;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  border-radius: 0.5rem;
}

.iphone-camera {
  flex-basis: 8%;
  height: 50%;
  background-color: lightgray;
  border-radius: 100%;
  margin-right: 1rem;
  display: flex;
  position: relative;
  top: 25%;
}

/* Iphone Buttons */
.iphone-volume-up,
.iphone-volume-down {
  width: 8px;
  height: 40px;
  background-color: var(--accent-two);
  position: absolute;
  left: -7.5%;
}

.iphone-volume-up {
  top: 70px;
}

.iphone-volume-down {
  top: 120px;
}

.iphone-power-button {
  width: 8px;
  height: 70px;
  background-color: var(--accent-two);
  position: absolute;
  top: 80px;
  right: -7%;
  cursor: pointer;
}

/* Iphone Screen */
.iphone-screen {
  width: 180px;
  height: 380px;
  background-color: black;
  color: white;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 20px;
  font-family: var(--font-family);
  background-size: cover;
  background-position: center;
  text-shadow: 2px 2px 2px black;
}

.iphone-screen.screen-on {
  background-image: url("../../../../Assets/Photos/screenSaver.jpg");
}

.iphone-screen.screen-on.first-screen,
.iphone-screen.screen-on.second-screen {
  background-image: none;
  background-color: var(--background-color);
}

.iphone-swipe-bar {
  width: 100px;
  height: 7px;
  background-color: white;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  cursor: pointer;
  filter: drop-shadow(5px 5px 5px black);
}

.iphone-main-screen {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.iphone-lock,
.iphone-date,
.iphone-time {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iphone-lock {
  flex: 0.5;
}

.iphone-date {
  flex: 1;
}

.iphone-time {
  flex: 1.5;
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
  font-weight: 900;
}

.iphone-main {
  flex: 6;
}

.iphone-extras {
  flex: 1;
  display: flex;
}

.iphone-camera-icon,
.iphone-torchlight-icon {
  flex: 1;
  display: flex;
  align-items: center;
}

.icon-shadow {
  filter: drop-shadow(5px 5px 5px black);
}

.iphone-camera-icon {
  justify-content: end;
  padding-right: 0.4rem;
}

/* First Screen */
.iphone-firstScreen-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--accent-one);
  padding-top: 1rem;
  height: 95%;
  text-shadow: none;
}

.iphone-arrow-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iphone-arrow {
  cursor: pointer;
}

.iphone-arrow:hover {
  color: var(--accent-two);
}

.iphone-firstScreen-content-1,
.iphone-firstScreen-content-2 {
  flex: 2;
  margin: 1rem;
  border-radius: 0.5rem;
}
