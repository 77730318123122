/* Container Positioning */
.footer-container {
  background-color: var(--footer-color);
  display: flex;
  flex-direction: column;
  height: 10vh;
  font-family: var(--font-family);
}

.footer-section {
  font-size: 2vh;
  flex: 1;
  color: var(--footer-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
