/* Container positioning */
.homeExperience-container {
  display: flex;
}

.experience-section {
  flex: 1;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;
  /* padding-top: 7rem;
  flex: 1;
  padding-top: 4rem;
  display: flex;
  height: var(--homeExperience-height);
  flex-direction: column; */
}

.experience-left,
.experience-right {
  background-color: var(--background-color);
  display: flex;
}

.experience-left-top,
.experience-left-bottom,
.experience-right-top {
  flex: 1;
}

.experience-left-middle {
  flex: 2;
}

.experience-right-top {
  display: flex;
}

.experience-right-top-left {
  flex: 5;
  text-align: right;
}

.experience-right-top-right {
  flex: 1;
  position: relative;
  top: 5.5rem;
  right: 7rem;
}

.experience-right-bottom {
  flex: 3;
  display: flex;
  flex-direction: column;
}

/* Skills at a glance */
.skills-container {
  display: flex;
  background-color: var(--background-color-);
  padding-left: 2rem;
  padding-right: 2rem;
}

.skill-section {
  flex: 1;
  background-color: var(--background-color-);
  text-align: center;
  margin: 1rem;
  border: 0.1rem solid var(--accent-one);
  border-radius: 1rem;
}

.skill-section h2 {
  padding-bottom: 1rem;
  border-bottom: 0.1rem dashed var(--accent-one);
}

.skill-section ul {
  list-style-type: none;
  padding: 0rem;
  margin-top: -0.4rem;
}

.skill-section li {
  padding: 0.4rem;
}

/* Text and element styles */
.number-experience {
  font: var(--number-font);
  position: relative;
  left: 5rem;
  top: 1rem;
  color: var(--accent-two);
}

.my-experience {
  font-size: 4.5rem;
  position: relative;
  left: 14rem;
  top: -2rem;
}

.my-experience-answer {
  font-size: 1.25rem;
  position: relative;
  left: 14rem;
  top: 2rem;
}

.experience-button-1 {
  position: relative;
  left: 13rem;
  top: -2rem;
}

.experience-button-2 {
  position: relative;
  left: 25rem;
  top: -5rem;
}

.at-a-glance {
  font-size: 2.5rem;
  font-family: "Poppins";
  color: var(--accent-one);
  position: relative;
  top: 3rem;
  padding-right: 8rem;
}

/* Magnifying glass animation */
@keyframes moveEight {
  0%,
  100% {
    transform: scale(1) translate(0, 0); /* Start and end at normal scale */
  }
  12.5% {
    transform: scale(2) translate(0, 0); /* Scaled up at the center */
  }
  25% {
    transform: scale(2) translate(-8rem, 6rem); /* Move to the bottom right */
  }
  37.5% {
    transform: scale(2) translate(-10rem, 9rem); /* Move further right, halfway up */
  }
  50% {
    transform: scale(2) translate(-8rem, 13rem); /* Move to top right */
  }
  62.5% {
    transform: scale(2) translate(2rem, 13rem); /* Top center */
  }
  75% {
    transform: scale(2) translate(3rem, 8rem); /* Top left */
  }
  87.5% {
    transform: scale(2) translate(-1rem, 6rem); /* Move further left, halfway down */
  }
}

.magnifying-glass {
  transform: scale(1);
  transition: transform 0.5s ease;
}

.magnifying-glass:hover {
  fill: var(--accent-two);
  animation: moveEight 6s ease;
}

/* For Phones */
@media (max-width: 768px) {
  .homeExperience-container {
    flex-direction: column;
    width: 100vw;
  }

  .experience-left,
  .experience-right {
    height: 100vh;
  }
}
