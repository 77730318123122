/* Container positioning */
.homeBlog-container {
  display: flex;
}

.blog-section {
  flex: 1;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;
  background-color: var(--background-color);
  /* height: var(--homeBlog-height);
  padding-top: 7rem;
  display: flex;
  flex-direction: column; */
}

/* Left Section */
.blog-left {
  flex: 1;
  display: flex;
}

.blog-left-top {
  flex: 1;
}

.blog-left-middle {
  flex: 2;
}

.blog-left-bottom {
  flex: 1;
}

/* Right Section */
.blog-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numberBlog {
  font: var(--number-font);
  position: relative;
  left: 5rem;
  top: 1rem;
  color: var(--accent-two);
}

.myBlog {
  font-size: 4.5rem;
  position: relative;
  left: 16rem;
  top: -4rem;
}

.myBlogAnswer {
  font-size: 1.25rem;
  position: relative;
  left: 14rem;
  top: -1rem;
}

/* For Phones */
@media (max-width: 768px) {
  .homeBlog-container {
    flex-direction: column;
    width: 100vw;
  }

  .blog-left,
  .blog-right {
    height: 100vh;
  }
}
