/*=============== HOME PAGE ===============*/

/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*=============== CSS VARIABLES ===============*/
:root {
  /*========== Section Heights ==========*/
  --footer-height: 25rem;

  --homeSection-height: 45rem;
  --homeAbout-height: 38rem;
  --homeExperience-height: 38.5rem;
  --homeProjects-height: 38rem;
  --homeBlog-height: 38rem;
  --homeResources-height: 38.5rem;

  /*========== Colors ==========*/
  --accent-one: #002e50; /* navy blue */
  --accent-two: #ec4021; /* orange */

  --background-color: #f5f5f5;
  --header-color: #f5f5f5;
  --header-text-color: var(--accent-one);
  --footer-color: var(--accent-one);
  --footer-text-color: #f5f5f5;

  /*========= Font and typography ==========*/
  --header-font: "Poppins", sans-serif;
  --footer-font: "Arial", sans-serif;
  --font-family: "Poppins", sans-serif;
  --font_4: normal normal bold 3rem roboto-thin, roboto, sans-serif;
  --font_0: normal normal bold 6rem roboto-bold, roboto, sans-serif;
  --font_3: normal normal lighter 1.1rem roboto-bold, roboto, sans-serif;
  --number-font: normal normal bold 4rem "Poppins", sans-serif;

  /*========= Font weight ==========*/
  --font-normal: 450;
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========= z index ==========*/
  --z-fixed: 100;
}

@media (min-width: 1024px) and (height < 1000px) {
  #small-and-mediums {
    display: none;
  }
}
