/* Container Positioning */
.homeResources-container {
  display: flex;
}

.resources-section {
  flex: 1;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;
  background-color: var(--background-color);
  /* height: var(--homeResources-height);
  flex: 1;
  display: flex;
  flex-direction: column; */
}

/* Right Section */
.resources-right {
  flex-direction: row;
}

.resources-iphone-container {
  flex: 1.5;
  display: flex;
  flex-direction: column;
}

.resources-iphone {
  flex: 3.3;
  padding-top: 1rem;
}

.iphone {
  position: absolute;
  right: 20%;
}

.resources-click-bar {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: var(--accent-one);
}

.resources-click-pwr-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.resources-click-pwr {
  flex: 1;
  display: flex;
  color: var(--accent-one);
}

.resources-arrow-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 0.6rem;
}

.resources-words {
  flex: 3.5;
  font-family: var(--font-family);
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
}

.click-button {
  position: relative;
  bottom: -1.4rem;
}

.swipe-bar {
  align-items: end;
  padding-right: 0.6rem;
}
.swipe-bar h4 {
  position: relative;
  top: -1.5rem;
  padding-bottom: 0.75rem;
}

.swipe-arrow {
  position: relative;
  left: 6rem;
  top: 0.5rem;
}

.resources-empty {
  flex: 1.8;
}

@keyframes bounce-left-right {
  0%,
  100% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
}

.bounce-left-right {
  animation: bounce-left-right 2s infinite ease-in-out;
}

@keyframes bounce-up-down {
  0%,
  100% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
}

.bounce-up-down {
  animation: bounce-up-down 2s infinite ease-in-out;
}

/* Left Section */
.resourcesLeftTop {
  flex: 1;
}

.resourcesLeftMiddle {
  flex: 2;
}

.resourcesLeftBottom {
  flex: 1;
}

.resourcesIPhone {
  position: relative;
  top: 5rem;
  right: 0rem;
}

.numberResources {
  font: var(--number-font);
  position: relative;
  left: 5rem;
  top: 1rem;
  color: var(--accent-two);
}

.myResources {
  font-size: 4.5rem;
  position: relative;
  left: 15rem;
  top: -4rem;
}

.myResourcesAnswer {
  font-size: 1.25rem;
  position: relative;
  left: 14rem;
  top: -1rem;
}

/* For Phones */
@media (max-width: 768px) {
  .homeResources-container {
    flex-direction: column;
    width: 100vw;
  }

  .resources-left,
  .resources-right {
    height: 100vh;
  }
}
