/* Laptop */
.laptop {
  perspective: 240px;
  width: 450px;
  height: 350px;
  margin: 50px auto;
}

/* Camera */
.camera {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #666;
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
}

/* Screen */
.screen {
  height: 15rem;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0.75rem solid var(--accent-one);
  transition: background-color 0.5s ease;
}

.screen.on {
  background-color: var(--background-color);
}

.laptop-text {
  color: var(--accent-one);
  font-size: 1rem;
  text-align: center;
  padding: 10px;
  font-family: "Poppins", sans-serif;
}

/* Blog Simulator */
.content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1rem;
  font-family: var(--font-family);
  font-size: 0.9rem;
}

.search-bar {
  flex: 1;
  border: 0.1rem solid var(--accent-one);
  margin: 0.5rem;
  border-radius: 0.4rem;
  display: flex;
}

.search-bar-filter {
  border-right: 0.1rem solid var(--accent-one);
}

.search-bar-filter,
.search-bar-glass {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar-search {
  flex: 8;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}

.topics-container {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.topic-row {
  flex: 1;
  display: flex;
}

.topic-box {
  flex: 1;
  margin: 0.5rem;
  border: 0.1rem solid var(--accent-one);
  display: flex;
  flex-direction: column;
}

.topic-box-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topic-box-icon {
  flex: 1;
  align-items: end;
}

.topic-box-word {
  flex: 1.5;
  font-size: 1.2rem;
}

a.topic-box,
a.topic-box:visited,
a.topic-box:active {
  color: var(--accent-one);
  text-decoration: none;
}

a.topic-box:hover {
  background-color: var(--accent-one);
  color: var(--background-color);
}

/* Keyboard */
.keyboard {
  height: 60px;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  transform: rotateX(70deg);
  transform-origin: top;
  border: 0.15rem solid var(--accent-one);
  border-top: none;
}

.keyboard-row {
  display: flex;
  flex: 1;
  width: 100%;
}

.key {
  flex: 1;
  margin: 1px;
  background-color: #ccc;
  border: 0.05rem solid black;
  border-radius: 2px;
  display: flex;
  justify-content: center;
}

.wide-key {
  flex: 1.5;
}

.extra-wide-key {
  flex: 6;
}

.power-button-container {
  flex: 2;
  cursor: pointer;
  font-family: var(--font-family);
  font-size: 0.6rem;
  font-weight: 900;
}

.power-button {
  background-color: var(--accent-two);
  color: var(--background-color);
}

.power-button:hover {
  background-color: var(--accent-one);
}

.power-button:active {
  filter: brightness(150%);
}
